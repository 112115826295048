import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { preloadLazyComponent } from 'common/utils/loading';
import { dcrActivityPath, homeActivityPath, siteActivityPath } from 'module/patient/activity/utils/constants';
import { lazy } from 'react';

export let routes = [
    {
        component: preloadLazyComponent(() => import('module/patient/create/CreatePatientPage')),
        permissions: ['Patient_Create'],
        path: '/patient/basicdetails',
        role: [RoleVariantEnum.SiteCoordinator, RoleVariantEnum.SiteInvestigator],
        studySiteRequired: true,
    },
    {
        component: preloadLazyComponent(() => import('module/patient/create/CreatePatientVisitSchedulePage')),
        permissions: ['Patient_Create'],
        path: '/patient/:patientId/:studyId/visitschedule',
        role: [RoleVariantEnum.SiteCoordinator, RoleVariantEnum.SiteInvestigator],
        studySiteRequired: true,
    },
    {
        component: preloadLazyComponent(() => import('./patientchart/PatientChartPage')),
        permissions: ['Patient_Create'],
        path: '/chart/:patientId',
        role: [
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
        ],
        studySiteRequired: true,
    },
    {
        component: preloadLazyComponent(() => import('./patientchart/PatientChartPage')),
        permissions: ['Patient_Create'],
        path: '/chart/:patientId/:patientDetails?',
        role: [
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
        ],
        studySiteRequired: true,
        canBeLandingPage: true,
    },
    {
        component: lazy(() => import('module/patient/econsent/econsent.page')),
        // @todo: Check permissions and roles for this page
        permissions: [],
        role: [],
        path: '/chart/:patientId/econsent/:studyPK/:patientSK/:patientName/:ICFSubRowHeader',
    },
    {
        component: lazy(() => import('module/patient/activity/HomeActivityPage')),
        permissions: ['StudyActivityInstance_Create', 'StudyActivityInstance_Edit'],
        path: homeActivityPath,
        role: [RoleVariantEnum.SiteCoordinator, RoleVariantEnum.SiteInvestigator, RoleVariantEnum.DataChangeSupport],
        studySiteRequired: true,
    },
    {
        component: lazy(() => import('module/patient/activity/HomeActivityPage')),
        permissions: ['StudyActivityInstance_Create', 'StudyActivityInstance_Edit'],
        path: dcrActivityPath,
        role: [RoleVariantEnum.DataChangeSupport],
        studySiteRequired: true,
    },
    {
        component: lazy(() => import('module/patient/activity/SiteActivityPage')),
        permissions: [
            'Study_Read',
            'Study_Create',
            'Study_Edit',
            'Study_Delete',
            'StudyActivityInstance_Create',
            'StudyActivityInstance_Edit',
        ],
        path: siteActivityPath,
        role: [
            BaseRoles.UserId,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
        ],
        studySiteRequired: true,
    },
    {
        component: preloadLazyComponent(() => import('./patientchart/InaccessiblePage')),
        permissions: [],
        role: [],
        path: '/patientAccessDenied',
    },
];
